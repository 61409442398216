import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

const ContactSection = ({ children }) => {
  return (
    <section class="section">
      <div class="container py-4">
        <h2 class="title has-text-centered mb-6">Contact Our Team</h2>
        <div class="columns">
          <div class="column is-6">
            {children}
            <div>
              <div class="media">
                <div class="media-left">
                  <figure class="image is-24x24">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>1802 Blanco Road, San Antonio, Texas 78212</p>
                  </div>
                </div>
              </div>
              <div class="media">
                <div class="media-left">
                  <figure class="image is-24x24">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>210-223-2627</p>
                  </div>
                </div>
              </div>
              <div class="media">
                <div class="media-left">
                  <figure class="image is-24x24">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <a
                      href="mailto:attorney@mcfirm.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      attorney@mcfirm.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-5 is-offset-1">
            <h4 class="title is-spaced is-4">Free case evaluation</h4>
            <p class="subtitle">
              Tell us about your case and learn how we can help.
            </p>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              action="/success"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <div class="field">
                <div class="control">
                  <input
                    class="input"
                    name="name"
                    type="text"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input
                    class="input"
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input
                    class="input"
                    name="phone"
                    type="text"
                    placeholder="Phone"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <textarea
                    class="textarea"
                    name="message"
                    rows="5"
                    placeholder="Write about your case..."
                  ></textarea>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <p class="is-hidden">
                    <label>
                      Don’t fill this out if you’re human:{" "}
                      <input name="bot-field" />
                    </label>
                  </p>
                  <button class="button is-primary is-fullwidth" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
