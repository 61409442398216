import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Seo from "../components/seo";
import Layout from "../components/layout";
import ContactSection from "../components/contact-section";

class ContactPage extends React.Component {
  render() {
    const page = get(this, "props.data.contentfulPage");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${page.title}`}
          keywords={`${page.keywords ? page.keywords.join() : ""}`}
          description={page.seoDescription}
        />
        <section className="has-background-white-bis">
          <ContactSection>
            <h2 class="title is-spaced is-4">Let's talk about your case</h2>
            <p class="subtitle">
              We're here to answer your questions and we can work with you to
              resolve your legal issues.
            </p>
          </ContactSection>
        </section>
        <hr />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55581.537058749986!2d-98.50770200000001!3d29.462505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c5f0cc26e1e31%3A0x55317389170a9b17!2sMartin%2C%20Cukjati%20%26%20Tom%2C%20LLP!5e0!3m2!1sen!2sus!4v1621636455063!5m2!1sen!2sus"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          title="Map"
        ></iframe>
      </Layout>
    );
  }
}

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQuery {
    contentfulPage(slug: { eq: "contact" }) {
      slug
      title
      keywords
      seoDescription
      body {
        raw
      }
      footer {
        raw
      }
    }
  }
`;
